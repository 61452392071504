<template>
  <div>
    <input class="checkbox" type="checkbox" :value="value" :name="id" />
    <label>{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Label"
    },
    value: {
      type: String,
      default: "value"
    },
    id: {
      type: String,
      default() {
        return `checbox-${this._uid}`;
      },
    },
  },
};
</script>
<style>
.checkbox {
  margin-right: 5px;
}
</style>